import React, { Component } from 'react';
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

import "./Blog.css";
import Spinner from "../Spinner";

class Blog extends Component {
  render() {
    const { posts } = this.props;

    if(posts) {
      return (
        <ScrollAnimation
          animateIn="fadeInUp"
          /* animateOnce="true" */
          delay={300}
        >
        <div className="row blog-row">
          <div className="col">
            <h1 style={{ color: "#e79239" }}><i className="far fa-newspaper"></i> <span className="satisfy">Aktuelle News</span></h1>
              <div className="card-columns">
              {posts.map(post => (
                <div className="card shadow" key={post.id}>
                  {post.image ? <img className="card-img-top blog-post-image" src={post.image} alt="Beitrags-Bild" />
                  : <img className="card-img-top" src="https://via.placeholder.com/370x160?text=Beitrags-Bild" alt="Bild" />
                }
                  <div className="card-body">
                    <h5 className="card-title">
                      {post.title}<br/>
                      <span style={{fontSize: "0.8rem"}} className="text-muted font-italic">
                        Veröffentlicht am {post.date}
                      </span>
                      {post.updated ? (
                        <span style={{fontSize: "0.8rem"}} className="text-muted font-italic">
                          {" "}/ Aktualisiert am {post.updated}
                        </span>
                      ) : null}
                    </h5>
                    <p className="card-text">{post.excerpt}...</p>
                    <Link to={`/news/post/${post.id}`} className="card-link">Lesen <i className="fas fa-glasses"></i></Link>
                  </div>
                </div>
              ))}
              </div>
          </div>
        </div>
        </ScrollAnimation>
      )
    } else {
      return (
        <div className="row blog-row">
          <div className="col">
            <Spinner />
          </div>
        </div>
      )
    }
  }
}

Blog.propTypes = {
  firestore: PropTypes.object.isRequired,
  posts: PropTypes.array
}

export default compose(
  firestoreConnect([{
    collection: "posts",
    orderBy: ["created", "desc"]
  }]),
  connect((state, props) => ({
    posts: state.firestore.ordered.posts
  }))
)(Blog);