import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScrollToTop from "./components/helpers/ScrollToTop";
import './App.css';
import { UserIsAuthenticated, UserIsNotAuthenticated } from "./helpers/auth";

// Redux
import { Provider } from "react-redux";
import store from "./store";

// Components
import AppNavbar from "./components/AppNavbar/AppNavbar"
//import Sidebar from "./components/Sidebar/Sidebar"
import Footer from "./components/Footer/Footer"
import Home from "./components/Home/Home"
import Blog from "./components/Blog/Blog"
import BlogPost from "./components/Blog/BlogPost"
import Gallery from "./components/Gallery/Gallery"
import Events from "./components/Events/Events"
import EventsSingle from "./components/Events/EventsSingle"
import Contact from "./components/Contact/Contact"
import Imprint from "./components/Imprint/Imprint"
import Dashboard from "./components/Dashboard/Dashboard"
import AddPost from "./components/Dashboard/AddPost"
import PostDetails from "./components/Dashboard/PostDetails"
import EditPost from "./components/Dashboard/EditPost"
//import UploadImage from "./components/Dashboard/UploadImage"
import Upload from "./components/Dashboard/Upload"
import Login from "./components/auth/Login"
import Register from "./components/auth/Register"
//import Settings from "./components/auth/Settings"

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <ScrollToTop>
            <div className="App">
              <AppNavbar />
              <div className="container-fluid App-main-cont">
                <Switch>
                  <Route exact path="/" component={Home} />
                  <Route exact path="/news" component={Blog} />
                  <Route exact path="/news/post/:id" component={BlogPost} />
                  <Route exact path="/galerie" component={Gallery} />
                  <Route exact path="/veranstaltungen" component={Events} />
                  <Route exact path="/veranstaltungen/:event" component={EventsSingle} />
                  <Route exact path="/kontakt" component={Contact} />
                  <Route exact path="/impressum-datenschutz" component={Imprint} />
                  <Route exact path="/dashboard" component={UserIsAuthenticated(Dashboard)} />
                  <Route exact path="/dashboard/beitrag-erstellen" component={UserIsAuthenticated(AddPost)} />
                  <Route exact path="/dashboard/post/:id" component={UserIsAuthenticated(PostDetails)} />
                  <Route exact path="/dashboard/post/edit/:id" component={UserIsAuthenticated(EditPost)} />
                  <Route exact path="/dashboard/galeriebild-hochladen" component={UserIsAuthenticated(Upload)} />
                  <Route exact path="/login" component={UserIsNotAuthenticated(Login)} />
                  <Route exact path="/register" component={UserIsNotAuthenticated(Register)} />
                  <Route exact path="/settings" component={UserIsAuthenticated(Dashboard)} />
                  {/* <Route exact path="/settings" component={UserIsAuthenticated(Settings)} /> */}
                </Switch>
              </div>
              <Footer />
            </div>
          </ScrollToTop>
        </Router>
      </Provider>
    );
  }
}

export default App;
