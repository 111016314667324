import React from 'react'

export default () => {
  return (
    <div className="row">
      <div className="col text-center">
        <h1 style={{ color: "var(--orange)" }} ><i className="fas fa-spinner fa-pulse isloading" /></h1>
      </div>
    </div>
  )
}