import React, { Component } from 'react';
import axios from "axios";
//import cloudinary from "cloudinary-core";
import { Link } from "react-router-dom";
import "./Dashboard.css";
import { FilePond, File, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

// Register the FilePond plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

// Cloudinary
//const cl = new cloudinary.Cloudinary({cloud_name: "goldbox", secure: true});

class Upload extends Component {
  constructor(props) {
    super(props);

    this.state = {
        // Set initial files
        files: []
    };
  }

  componentDidMount() {
    axios.get("https://res.cloudinary.com/goldbox/image/list/glaswohnen.json")
    .then(res => console.log(res))
    .catch(err => console.log(err))
  }

  handleInit() {
      console.log('FilePond instance has initialised', this.pond);
  }

  render() {
  	return (
      <div style={{ minHeight: "100vh" }}>
        <div className="row">
          <div className="col dashboard-main-col">
            {/* Main content */}
            <Link to="/dashboard" className="btn btn-link ml-5 pb-3">
              <i className="fas fa-arrow-circle-left" /> Zurück zum Dashboard
            </Link>
            <hr />

            <div className="card upload-form-card">
              <FilePond ref={ref => this.pond = ref}
                allowMultiple={true}
                maxFiles={3}
                server="https://api.cloudinary.com/v1_1/goldbox/upload"
                oninit={() => this.handleInit() }
                onupdatefiles={(fileItems) => {
                    // Set current file objects to this.state
                    this.setState({
                        files: fileItems.map(fileItem => fileItem.file)
                    });
                    console.log(this.state.files)
                }}
              >
                {/* Update current files  */}
                {this.state.files.map(file => (
                    <File key={file} src={file} origin="local" />
                ))}

              </FilePond>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Upload;