import React, { Component } from 'react'
import { Link } from "react-router-dom"
import "./Footer.css"

import Logo from "../../assets/images/goldbox-logo.png"

class Footer extends Component {
  render() {
  	return (
      <div className="container-fluid footer">
        <div className="row shadow-lg">
          <div className="col-md-4 text-center footer-logo">
            <h6>Öffnungszeiten</h6>
            <img src={Logo} alt="Logo" />
            <h6 className="pt-3">Do / Fr / Sa</h6>
            <h6>
              von 10.00 - 13.00 Uhr<br />
              und nach Vereinbarung
            </h6>
          </div>
          <div className="col-md-4 text-center footer-title">
            <h1 className="">Sybille Philippart-Weber</h1>
            <h2 className="">Goldschmiedemeisterin</h2>
            <h6 className="">Kirchstr. 61 - 77743 Neuried-Altenheim</h6>
            <h6 className="">+49(0)7807-957467</h6>
            <h6 className="">sybille@schmuckschmied.eu</h6>
          </div>
          <div className="col-md-4 text-right">
            <ul className="navbar-nav mr-auto footer-nav">
              <li className="nav-item">
                <Link to="/" className="nav-link footer-link">
                  Startseite
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/news" className="nav-link footer-link">
                  News
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/galerie" className="nav-link footer-link">
                  Galerie
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/veranstaltungen" className="nav-link footer-link">
                  Veranstaltungen
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/kontakt" className="nav-link footer-link">
                  Kontakt
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/impressum-datenschutz" className="nav-link footer-link">
                  Impressum | Datenschutz
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="row pt-5">
          <div className="col text-center">
            <a href="https://w3code.one" target="_blank" rel="noopener noreferrer" className="w3code">
              created with &hearts; by w3&lt;code&gt;one Inh. Nils Domin, Oberkirch
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default Footer