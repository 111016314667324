import React, { Component } from 'react'
import { Link, NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { firebaseConnect } from "react-redux-firebase";
import "./AppNavbar.css"

import Logo from "../../assets/images/favicon.jpg"

class AppNavbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      isAuthenticated: false
    };
  }

  toggleNav = () => {
    this.setState({ isOpen: !this.state.isOpen });
  }

  static getDerivedStateFromProps(props, state) {
    const { auth } = props;

    if(auth.uid) {
      return { isAuthenticated: true }
    } else {
      return { isAuthenticated: false }
    }
  }

  onLogoutClick = e => {
    e.preventDefault();

    const { firebase } = this.props;
    firebase.logout();
  }

  render() {
    let collapse = this.state.isOpen
      ? "collapse navbar-collapse"
      : "collapse navbar-collapse nav-hidden";

    const { isAuthenticated } = this.state;
    // const { auth } = this.props; // for the NavLink with auth.email
    const { allowRegistration } = this.props.settings;

  	return (
      <nav className="navbar navbar-expand-md navbar-dark mb-4 fixed-top shadow custom-navbar">
        <div className="container-fluid">
          <Link to="/" className="navbar-brand">
            <img src={Logo} alt="Logo" style={{ width: "30%" }}/>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarMain"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={collapse} id="navbarMain">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <NavLink exact to="/" className="nav-link" onClick={this.toggleNav}>
                  Startseite
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink exact to="/news" className="nav-link" onClick={this.toggleNav}>
                  News
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink exact to="/galerie" className="nav-link" onClick={this.toggleNav}>
                  Galerie
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink exact to="/veranstaltungen" className="nav-link" onClick={this.toggleNav}>
                  Veranstaltungen
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink exact to="/kontakt" className="nav-link" onClick={this.toggleNav}>
                  Kontakt
                </NavLink>
              </li>
            </ul>
            {isAuthenticated ? (
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item">
                    <NavLink exact to="/dashboard" className="nav-link" onClick={this.toggleNav}>
                      <i className="fas fa-cogs"></i> Dashboard
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <a href="!#" className="nav-link" onClick={this.onLogoutClick}>
                      Logout <i className="fas fa-sign-out-alt"></i>
                    </a>
                  </li>
                </ul>
              ) : null}
              {!allowRegistration && !isAuthenticated ? (
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item">
                    <Link to="/login" className="nav-link">
                      Login
                    </Link>
                  </li>
                </ul>
              ) : null}
              {allowRegistration && !isAuthenticated ? (
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item">
                    <Link to="/login" className="nav-link">
                      Login
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/register" className="nav-link">
                      Register
                    </Link>
                  </li>
                </ul>
              ) : null}
          </div>
        </div>
      </nav>
    )
  }
}

AppNavbar.propTypes = {
  firebase: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired
}

export default compose(
  firebaseConnect(),
  connect((state, props) => ({
    auth: state.firebase.auth,
    settings: state.settings
  }))
)(AppNavbar)