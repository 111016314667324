import React, { Component } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import "./Events.css";

class EventsSingle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      images: []
    }
  }

  componentDidMount() {
    console.log(this.props.match.params.event)
    axios.get(`https://res.cloudinary.com/goldbox/image/list/${this.props.match.params.event}.json`)
      .then((data) => {
        this.setState({ images: data.data.resources })
        console.log(this.state)
      })
  }

  render() {
      return (
        <div className="row events-row">
          <div className="col">
            <Link to="/veranstaltungen" className="btn btn-link pb-1">
              <i className="fas fa-arrow-circle-left" /> Zurück zur Übersicht
            </Link>
            <hr />
            <h1 style={{ color: "#e79239" }}>
              <i className="fas fa-images"></i>{" "}
              <span className="satisfy">Veranstaltungs-Schlagwort:{" "}
{/*                 <small style={{fontFamily: "sans-serif", fontStyle: "initial", color: "initial"}}>
                  {this.props.match.params.event.charAt(0).toUpperCase()+this.props.match.params.event.slice(1)}
                </small> */}
                <span class="badge badge-pill badge-goldbox">
                  {this.props.match.params.event}
                </span>
              </span>
            </h1>
            <div className="card-columns">
              {this.state.images.map(image => (
                <div className="card mt-3 shadow" key={image.public_id}>
                  <img
                    src={
                      "https://res.cloudinary.com/goldbox/image/upload/" +
                      image.public_id +
                      ".jpg"
                    }
                    alt=""
                    className="img-fluid event-img"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      )
  }
}

export default EventsSingle;