import React, { Component } from 'react';
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { compose } from "redux";
import { firebaseConnect } from "react-redux-firebase";
import { firestoreConnect } from "react-redux-firebase";
import "./Dashboard.css";

const btnDisabled = {
  cursor: "not-allowed",
  borderStyle: "dashed",
  opacity: ".5"
};

class AddPost extends Component {
  constructor(props) {
    super(props);

    this.fileInput = React.createRef();

    this.state= {
      title: "",
      content: "",
      excerpt: "",
      fileInput: false,
      localImage: "",
      image: "",
      fileAlert: false
    }
  }

  onFileInputClick = e => {
    this.setState({ fileInput: true })
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });

    if(this.state.fileInput) {

      const reader = new FileReader();
      const file = this.fileInput.current.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.setState({ localImage: this.fileInput.current.files[0], image: reader.result });
        const fileSize = file.size/1.0e+06 // convert file.size's bytes to megabytes
        if(fileSize > 1 || reader.result === this.state.bigImage) {
          this.setState({
            fileAlert: true
          });
          document.getElementById("postImageUpload").value = "";
        }
      }
    }


  }

  dismissAlert = e => {
    this.setState({
      localImage: "",
      image: "",
      fileAlert: false
    });

  }

  deletePreview = e => {
    this.setState({ image: ""})
    document.getElementById("postImageUpload").value = "";
  }

  onSubmit = e => {
    e.preventDefault();
    const { firebase, firestore, history } = this.props;

    let dateObj = new Date();
    let month = dateObj.getMonth() + 1; //months from 1-12
    let day = dateObj.getDate();
    let twodigitday = day < 10 ? ("0" + day) : day
    let twodigitmonth = month < 10 ? ("0" + month) : month
    let year = dateObj.getFullYear();
    let newdate = twodigitday + "." + twodigitmonth + "." + year;

    const newPost = {
      created: firebase.firestore.FieldValue.serverTimestamp(),
      date: newdate,
      title: this.state.title,
      content: this.state.content,
      excerpt: this.state.excerpt,
      image: this.state.image,
    }

    firestore.add({ collection: "posts" }, newPost)
      .then(() => history.push("/news"))
  }


  render() {
    let submitBtn = this.state.title && this.state.content && this.state.excerpt !== "" && !this.state.fileAlert ? (
      <input type="submit" value="Veröffentlichen" className="btn btn-success btn-lg btn-block submitBtn" />
    ) : (
      <input type="submit" value="Veröffentlichen" className="btn btn-outline-secondary btn-lg btn-block submitBtn" style={btnDisabled} />
    );

    const imagePreview = this.state.image !== "" ? (
      <p className="mt-2 mb-2">
        <img src={this.state.image} alt="" style={{ maxHeight: "70px" }} />
        <button type="button" className="close float-left mr-2" aria-label="Close" onClick={this.deletePreview} title="Auswahl löschen">
          <span aria-hidden="true" className="text-danger">&times;</span>
        </button>
      </p>
    ) : null;

  	return (
      <div style={{ minHeight: "100vh" }}>
        <div className="row">
          <div className="col dashboard-main-col">
            {/* Main content */}
            <Link to="/dashboard" className="btn btn-link ml-5 pb-3">
              <i className="fas fa-arrow-circle-left" /> Zurück zum Dashboard
            </Link>
            <hr />

            <div className="card form-card">
              <div className="card-header">
                <h3 className="dashboard-title">
                  <i className="fas fa-pencil-alt pr-2"></i> Beitrag erstellen
                </h3>
              </div>
              <div className="card-body">
                <form onSubmit={this.onSubmit} className="addpost-form">
                  <div className="form-group">
                    <label htmlFor="title">Titel</label>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      required
                      onChange={this.onChange}
                      value={this.state.title}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="content">Text</label>
                    <textarea
                      className="form-control"
                      name="content"
                      rows="10"
                      required
                      onChange={this.onChange}
                      value={this.state.content}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="content">Textauszug (für die Vorschau in der News-Übersicht)</label>
                    <textarea
                      className="form-control"
                      name="excerpt"
                      minLength="50"
                      maxLength="130"
                      rows="3"
                      required
                      onChange={this.onChange}
                      value={this.state.excerpt}
                    />
                    <small className="form-text text-muted">
                      Für die Vorschau in der News-Übersicht.
                      <span className="text-info"> Mind. 50 und max. 130 Zeichen.</span>
                    </small>
                  </div>
                  <div className="form-group">
                    <label htmlFor="image">Beitrags-Bild</label>
                    <div className="input-group">
                    {this.state.fileAlert ? (
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="postImageUpload"
                            aria-describedby="postImageUpload"
                            onChange={this.onChange}
                            ref={this.fileInput}
                            disabled
                            style={{cursor: "not-allowed"}}
                          />
                          <label className="custom-file-label" htmlFor="inputGroupFile04" id="customFile">Datei auswählen...</label>
                        </div>
                      ) : (
                        <div className="custom-file" onClick={this.onFileInputClick}>
                          <input
                            type="file"
                            className="custom-file-input"
                            id="postImageUpload"
                            aria-describedby="postImageUpload"
                            onChange={this.onChange}
                            ref={this.fileInput}
                            style={{cursor: "pointer"}}
                          />
                          <label className="custom-file-label" htmlFor="inputGroupFile04" id="customFile">Datei auswählen...</label>
                        </div>
                      )}
                    </div>
                    {this.state.fileAlert ? (
                      <div className="alert alert-danger alert-dismissible fade show mt-2" role="alert">
                        <strong>
                          <i className="fas fa-exclamation-triangle" />{" "}
                          FEHLER:
                        </strong>{" "}
                          Die Datei darf nicht größer sein als <strong>1 MB</strong>!
                        <button type="button" className="close" aria-label="Close" onClick={this.dismissAlert}>
                          <small>Ich habe verstanden </small><span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                    ) : imagePreview}
                  </div>
                  {submitBtn}
                </form>
              </div>
            </div>
          </div>

        </div>
      </div>
    )
  }
}

AddPost.propTypes = {
  firestore: PropTypes.object.isRequired
};

export default compose(
  firebaseConnect(),
  firestoreConnect()
)(AddPost);