import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "./Contact.css";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

const btnDisabled = {
  cursor: "not-allowed",
  borderStyle: "dashed"
};

class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
      privacy: false,
      isLoading: false
    };
  }

  handleChange = event => {
    const target = event.target;
    let value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  onCheck = e => {
    this.setState({
      privacy: !this.state.privacy
    });
  };

  snackbar = () => {
    const x = document.getElementById("snackbar");
    x.className = "show";
    setTimeout(() => {
      x.className = x.className.replace("show", "")
    }, 3000);
  }

  onSubmit = e => {
    e.preventDefault();

    this.setState({
      isLoading: true
    });

    if (
      this.state.name === "" ||
      this.state.email === "" ||
      this.state.subject === "" ||
      this.state.message === ""
    ) {
      alert("Es fehlen noch Angaben!");
      this.setState({ isLoading: false });
    } else {
      axios({
        method: "POST",
        url: "https://goldboxnodemailer.herokuapp.com/api/contact",
        data: {
          name: this.state.name,
          email: this.state.email,
          phone: this.state.phone,
          subject: this.state.subject,
          message: this.state.message
        }
      }).then(response => {
        if (response.data.msg === "success") {
          this.snackbar();
          this.setState({
            name: "",
            email: "",
            phone: "",
            subject: "",
            message: "",
            isLoading: false,
          });
        } else if (response.data.msg === "fail") {
          console.log(response);

          alert("Fehler bei der Übermittlung.\n Bitte versuchen Sie es nochmal.");
        }
      });
    }
  };

  render() {
    let loadingSpinner = this.state.isLoading ? (
      <i className="fas fa-spinner fa-pulse" />
    ) : null;

    let submitBtn = this.state.privacy ? (
      <div className="col-md-12 text-center">
        <button type="submit" className="btn btn-primary btn-lg submitBtn">
          Senden <i className="far fa-paper-plane ml-3" />
        </button>
      </div>
    ) : (
      <div className="col-md-12 text-center">
        <button
          type="button"
          className="btn btn-outline-secondary btn-lg"
          style={btnDisabled}
          disabled
        >
          Senden <i className="far fa-paper-plane ml-3" />
        </button>
      </div>
    );

    return (
      <div className="kontakt-cont">
      <ScrollAnimation
          animateIn="fadeInLeft"
          delay={300}
        >
        <h1 className="text-center satisfy">Kontakt zu mir</h1>

        <div className="row kontakt-first-ribbon text-center">
          <div className="col-md-4">
            <h1>
              <i className="fas fa-address-card" />
            </h1>
            <p>
              Sybille Philippart-Weber
              <br />
              Kirchstr. 61, 77743 Neuried-Altenheim
            </p>
          </div>

          <div className="col-md-4">
            <h1>
              <i className="fas fa-phone-square" /> <i className="fas fa-at" />
            </h1>
            <p>
              +49(0)7807-957467
              <br />
              kontakt@schmuckschmied.eu
            </p>
          </div>
          <div className="col-md-4">
            <h1>
              <i className="fas fa-map" />
            </h1>
            <a
              href="https://goo.gl/maps/XEhDt4hasSG2"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#343a40" }}
            >
              <p>
                Routenplaner auf Google Maps<span style={{ fontSize: "0.8rem" }}> <i className="fas fa-external-link-alt"></i></span>
                <br />
                <small className="font-italic">(Link zu externer Seite)</small>
              </p>
            </a>
          </div>
        </div>
        </ScrollAnimation>
        <ScrollAnimation
          animateIn="fadeInRight"
          /* animateOnce="true" */
          delay={300}
        >
        <div className="row kontakt-second-ribbon">
          <div className="col-md-12">
            <div>
              <h5 className="text-center">
                Gerne stehe ich Ihnen für eine persönliche Beratung zur Verfügung. Ich freue mich auf Ihre Kontaktaufnahme.
              </h5>
              <form
                className="needs-validation pt-5 kontakt-form"
                id="contact-form"
                onSubmit={this.onSubmit}
                method="POST"
                action="send"
              >
                <div className="form-group row">
                  <label
                    htmlFor="name"
                    className="col-sm-2 offset-sm-2 kontakt-form-label"
                  >
                    Vor- und Nachname
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      value={this.state.name}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="email"
                    className="col-sm-2 offset-sm-2 kontakt-form-label"
                  >
                    Email-Adresse
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      name="email"
                      className="form-control"
                      value={this.state.email}
                      onChange={this.handleChange}
                    />
                    <small
                      id="passwordHelpBlock"
                      className="form-text text-muted form-hint"
                    >
                      Wird nur für meine Rückmeldung aufgrund Ihrer Anfrage
                      genutzt.
                    </small>
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="phone"
                    className="col-sm-2 offset-sm-2 kontakt-form-label"
                  >
                    Telefon
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      name="phone"
                      className="form-control"
                      value={this.state.phone}
                      onChange={this.handleChange}
                    />
                    <small
                      id="passwordHelpBlock"
                      className="form-text text-muted form-hint"
                    >
                      Wird nur für meine Rückmeldung aufgrund Ihrer Anfrage
                      genutzt.
                    </small>
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="subject"
                    className="col-sm-2 offset-sm-2 kontakt-form-label"
                  >
                    Betreff
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      name="subject"
                      className="form-control"
                      value={this.state.subject}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="message"
                    className="col-sm-2 offset-sm-2 kontakt-form-label"
                  >
                    Ihre Nachricht
                  </label>
                  <div className="col-sm-6">
                    <textarea
                      type="text"
                      name="message"
                      className="form-control"
                      rows="5"
                      value={this.state.message}
                      onChange={this.handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col text-center">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        name="privacy"
                        value={this.state.privacy}
                        checked={this.state.privacy}
                        onChange={this.onCheck}
                        id="privacyCheck"
                      />
                      <label
                        className="form-check-label kontakt-check-label"
                        htmlFor="privacyCheck"
                      >
                        Ich habe die{" "}
                        <Link
                          to="/impressum-datenschutz"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Datenschutzbestimmungen
                        </Link>{" "}
                        gelesen.
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-md-12 text-center pt-3 pb-3"
                    style={{ fontSize: "1.5em" }}
                  >
                    {this.state.isLoading ? loadingSpinner : submitBtn}
                    <div id="snackbar">
                      <span role="img" aria-labelledby="warning" style={{ fontSize: "1em" }}>
                        😃
                      </span>{" "}Vielen Dank! Ihre Nachricht wurde gesendet.
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        </ScrollAnimation>
      </div>
    );
  }
}

export default Contact;