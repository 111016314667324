import React, { Component } from 'react';
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import Spinner from "../Spinner";
import "./Blog.css";

class BlogPost extends Component {
  render() {
    const { post } = this.props;

    if(post) {
      return (
        <div className="row blog-row">
          <div className="col">
            <Link to="/news" className="btn btn-link pb-3">
              <i className="fas fa-arrow-circle-left" /> Zur Übersicht
            </Link>
            <div className="card shadow" key={post.id} style={{width: "50rem", margin: "0 auto"}}>
              {post.image ?
                (
                  <img className="card-img-top" src={post.image} alt="Beitrags-Bild" />
                )
              : (
                  <img className="card-img-top" src="https://via.placeholder.com/370x160?text=Beitrags-Bild" alt="Bild"/>
                )
            }
              <div className="card-body">
                <h1 className="card-title">{post.title}</h1>
                <p className="card-text">{post.content}</p>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="row blog-row">
          <div className="col">
            <Spinner />
          </div>
        </div>
      )
    }
  }
}

BlogPost.propTypes = {
  firestore: PropTypes.object.isRequired
}

export default compose(
  firestoreConnect(props => [
    {
      collection: "posts",
      storeAs: "post",
      doc: props.match.params.id
    }
  ]),
  connect(({firestore: {ordered}}, props) => ({
    post: ordered.post && ordered.post[0]
  }))
)(BlogPost);