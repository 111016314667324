import React, { Component } from 'react';
import axios from "axios";
import "./Gallery.css";

class Gallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      images: []
    }
  }

  componentDidMount() {
    axios.get("https://res.cloudinary.com/goldbox/image/list/schmuck.json")
      .then((data) => {
        this.setState({ images: data.data.resources })
        console.log(this.state)
      })
  }

  render() {
      return (
        <div className="row gallery-row">
          <div className="col">
            <h1 style={{ color: "#e79239" }}><i className="fas fa-images"></i> <span className="satisfy">Galerie</span></h1>
            <div className="card-columns">
              {this.state.images.map(image => (
                <div className="card mt-3 shadow" key={image.public_id}>
                  <img
                    src={
                      "https://res.cloudinary.com/goldbox/image/upload/" +
                      image.public_id +
                      ".jpg"
                    }
                    alt=""
                    className="img-fluid gallery-img"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      )
  }
}

export default Gallery;