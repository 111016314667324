import React, { Component } from 'react';
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import Spinner from "../Spinner";
import "./Dashboard.css";

const btnDisabled = {
  cursor: "not-allowed",
  borderStyle: "dashed",
  opacity: ".5"
};

class EditPost extends Component {
  constructor(props) {
    super(props);

    this.state = {
      localImage: "",
      image: "",
      fileAlert: false
    }

    // Create refs
    this.titleInput = React.createRef();
    this.contentInput = React.createRef();
    this.excerptInput = React.createRef();
    this.fileInput = React.createRef();
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value, localImage: this.fileInput.current.files[0] });
    const reader = new FileReader();
    const file = this.fileInput.current.files[0];

    reader.readAsDataURL(file);

    reader.onload = () => {
      this.setState({ image: reader.result });
      const fileSize = file.size/1.0e+06 // convert file.size's bytes to megabytes

      if(fileSize > 1 || reader.result === this.state.bigImage) {
        this.setState({
          fileAlert: true
        });
        document.getElementById("postImageUpload").value = "";
      }
    }
  }

  dismissAlert = e => {
    this.setState({
      localImage: "",
      image: "",
      fileAlert: false
    });

  }

  deletePreview = e => {
    this.setState({ image: ""})
    document.getElementById("postImageUpload").value = "";
  }

  onSubmit = e => {
    e.preventDefault();
    const { post, firebase, firestore, history } = this.props;

    let dateObj = new Date();
    let month = dateObj.getMonth() + 1; //months from 1-12
    let day = dateObj.getDate();
    let twodigitday = day < 10 ? ("0" + day) : day
    let twodigitmonth = month < 10 ? ("0" + month) : month
    let year = dateObj.getFullYear();
    let newdate = twodigitday + "." + twodigitmonth + "." + year;

    const updatePost = {
      created: firebase.firestore.FieldValue.serverTimestamp(),
      updated: newdate,
      title: this.titleInput.current.value,
      content: this.contentInput.current.value,
      excerpt: this.excerptInput.current.value,
      image: this.state.image
    }

  // Update Post in Firestore
  firestore.update({ collection: "posts", doc: post.id}, updatePost)
  .then(history.push("/dashboard"));
  }

  render() {
    const { post } = this.props;

    let submitBtn = this.titleInput && this.contentInput && this.excerptInput !== "" && !this.state.fileAlert ? (
      <input type="submit" value="Aktualisieren" className="btn btn-success btn-lg btn-block submitBtn" />
    ) : (
      <input type="submit" value="Aktualisieren" className="btn btn-outline-secondary btn-lg btn-block submitBtn" style={btnDisabled} />
    );

    const imagePreview = this.state.image !== "" ? (
      <p className="mt-2 mb-2">
        <img src={this.state.image} alt="" style={{ maxHeight: "70px" }} />
        <button type="button" className="close float-left mr-2" aria-label="Close" onClick={this.deletePreview} title="Auswahl löschen">
          <span aria-hidden="true" className="text-danger">&times;</span>
        </button>
      </p>
    ) : null;

    if(post) {
      return (
        <div style={{ minHeight: "100vh" }} className="dashboard-cont">
          <div className="row">
            <div className="col dashboard-main-col">
              <h1 className="dashboard-title ml-5">Dashboard</h1>
              <hr />
              <Link to={`/dashboard/post/${post.id}`} className="btn btn-link ml-5 pb-3">
                <i className="fas fa-arrow-circle-left" /> Zurück zum Beitrag
              </Link>
              <div className="card form-card">
                <div className="card-header">
                  <h3 className="dashboard-title">
                    <i className="fas fa-pencil-alt pr-2"></i> Beitrag bearbeiten
                  </h3>
                </div>
                <div className="card-body">
                  <p>{post.id}</p>
                  <form onSubmit={this.onSubmit} className="addpost-form">
                    <div className="form-group">
                      <label htmlFor="title">Titel</label>
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        required
                        ref={this.titleInput}
                        defaultValue={post.title}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="content">Text</label>
                      <textarea
                        className="form-control"
                        name="content"
                        rows="10"
                        required
                        ref={this.contentInput}
                        defaultValue={post.content}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="content">Textauszug (für die Vorschau in der News-Übersicht)</label>
                      <textarea
                        className="form-control"
                        name="excerpt"
                        minLength="50"
                        maxLength="130"
                        rows="3"
                        required
                        ref={this.excerptInput}
                        defaultValue={post.excerpt}
                      />
                      <small className="form-text text-muted">
                        Für die Vorschau in der News-Übersicht.
                        <span className="text-info"> Mind. 50 und max. 130 Zeichen.</span>
                      </small>
                    </div>
                    <div className="form-group">
                    <label htmlFor="image">Beitrags-Bild (max. Dateigröße: 1 MB)</label>
                    <div className="input-group">
                      {this.state.fileAlert ? (
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="postImageUpload"
                            aria-describedby="postImageUpload"
                            onChange={this.onChange}
                            ref={this.fileInput}
                            disabled
                            style={{cursor: "not-allowed"}}
                          />
                          <label className="custom-file-label" htmlFor="inputGroupFile04" id="customFile">Datei auswählen...</label>
                        </div>
                      ) : (
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="postImageUpload"
                            aria-describedby="postImageUpload"
                            onChange={this.onChange}
                            ref={this.fileInput}
                            style={{cursor: "pointer"}}
                          />
                          <label className="custom-file-label" htmlFor="inputGroupFile04" id="customFile">Datei auswählen...</label>
                        </div>
                      )}
                    </div>
                    {this.state.fileAlert ? (
                      <div className="alert alert-danger alert-dismissible fade show mt-2" role="alert">
                        <strong>
                          <i className="fas fa-exclamation-triangle" />{" "}
                          FEHLER:
                        </strong>{" "}
                          Die Datei darf nicht größer sein als <strong>1 MB</strong>!
                        <button type="button" className="close" aria-label="Close" onClick={this.dismissAlert}>
                          <small>Ich habe verstanden </small><span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                    ) : imagePreview}
                  </div>
                    {submitBtn}
                  </form>
                </div>
              </div>
            </div>

          </div>
        </div>
      )
    } else {
      return (
        <div style={{ minHeight: "100vh" }}>
          <div className="row">
            <div className="col-md-10 dashboard-main-col">
              <h1 className="dashboard-title ml-5">Dashboard</h1>
              <hr />
              <div className="row blog-row">
                <div className="col">
                  <Spinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
}

EditPost.propTypes = {
  firestore: PropTypes.object.isRequired
}

export default compose(
  firebaseConnect(),
  firestoreConnect(props => [
    {
      collection: "posts",
      storeAs: "post",
      doc: props.match.params.id
    }
  ]),
  connect(({firestore: {ordered}}, props) => ({
    post: ordered.post && ordered.post[0]
  }))
)(EditPost);