import React, { Component } from 'react';
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import Spinner from "../Spinner";
import "./Dashboard.css";

class PostDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    }
  }

  toggleDeleteConfirmation = e => {
    e.preventDefault();
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  onDeletePost = () => {
    const { post, firestore, history } = this.props;

    firestore.delete({ collection: "posts", doc: post.id })
      .then(history.push("/dashboard"))
  }

  render() {
    const { post } = this.props;
    const { isOpen } = this.state;

    if(post) {
      return (
        <div style={{ minHeight: "100vh" }} className="dashboard-cont">
          <div className="row">
            <div className="col dashboard-main-col">
              <h1 className="dashboard-title ml-5">Dashboard</h1>
              <hr />
              <Link to="/dashboard" className="btn btn-link ml-5 pb-3">
                <i className="fas fa-arrow-circle-left" /> Zur Übersicht
              </Link>
              <div className="row dashboard-cards-row">
                <div className="col">
                  <div className="card shadow">
                    <div className="card-header">
                      <span className="text-muted" style={{ fontSize: "0.7em" }}>Beitrags-ID: {post.id}</span><br/>
                      <span className="font-weight-bold" style={{ fontSize: "2em" }}>{post.title}</span>
                      {isOpen ? (
                        <div className="btn-group float-right">
                          <button
                            className="btn btn-dark"
                            disabled
                          >
                            Bearbeiten
                          </button>
                          <button
                            className="btn btn-danger"
                            disabled
                          >
                            Löschen
                          </button>
                        </div>
                      ) : (
                        <div className="btn-group float-right">
                          <Link to={`/dashboard/post/edit/${post.id}`} className="btn btn-dark">Bearbeiten</Link>
                          <button
                            className="btn btn-danger"
                            onClick={this.toggleDeleteConfirmation}
                          >
                            Löschen
                          </button>
                        </div>
                      )}
                      {isOpen ? (
                        <div className="row">
                          <div className="col-md-6 offset-md-6 delete-col">
                            <h3><i className="fas fa-exclamation-triangle text-danger"></i> Bist Du sicher?</h3>
                            <p className="text-center">Dies kann nicht rückgängig gemacht werden, der Beitrag wird für immer gelöscht!</p>
                            <div className="float-right">
                              <button type="button" className="btn btn-secondary mr-1" onClick={this.toggleDeleteConfirmation}>Abbrechen</button>
                              <button
                                type="button"
                                className="btn btn-danger ml-1"
                                onClick={this.onDeletePost}
                              >
                                Ja, bitte löschen!
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className="card-body">
                      {
                        post.image === "" ?
                          <img src="https://via.placeholder.com/350x150" alt="Beitrags-Bild" className="img-fluid" />
                        : <img src={post.image} alt="Beitrags-Bild" className="img-thumbnail" style={{ width: "10%" }}/>
                      }
                      <p className="pt-3">{post.content}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      )
    } else {
      return (
        <div style={{ minHeight: "100vh" }}>
          <div className="row">
            <div className="col-md-12 dashboard-main-col">
              <h1 className="dashboard-title ml-5">Dashboard</h1>
              <hr />
              <div className="row blog-row">
                <div className="col">
                  <Spinner />
                </div>
              </div>
            </div>
          </div>

        </div>
        )
    }
  }
}

PostDetails.propTypes = {
  firestore: PropTypes.object.isRequired
}

export default compose(
  firestoreConnect(props => [
    {
      collection: "posts",
      storeAs: "post",
      doc: props.match.params.id
    }
  ]),
  connect(({firestore: {ordered}}, props) => ({
    post: ordered.post && ordered.post[0]
  }))
)(PostDetails);