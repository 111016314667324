import React, { Component } from 'react';
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import Spinner from "../Spinner";
import "./Dashboard.css";

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      postId: ""
    }
  }

  toggleDeletePostConfirmation = e => {
    console.log(e);
    this.setState({
      isOpen: !this.state.isOpen,
      postId: e.id
    })
  }

  onDeletePost = () => {
    const { firestore } = this.props;
    const { postId } = this.state;

    firestore.delete({ collection: "posts", doc: postId })
      .then(this.setState({
        isOpen: false,
        postId: ""
      }))
  }

  render() {
    const { posts } = this.props;
    const { isOpen } = this.state;

    if(posts) {
      return (
        <div style={{ minHeight: "100vh" }} className="dashboard-cont">
          <div className="row">
            <div className="col dashboard-main-col">
              <div className="row">
                <div className="col">
                <span className="dashboard-title ml-5 font-weight-bold" style={{ fontSize: "3em" }}><i className="fas fa-cogs"></i> Dashboard</span>
                  {/* <div className="btn btn-primary btn-lg float-right mr-5 dashboard-addpost-btn">
                    <Link to="/dashboard/beitrag-erstellen">
                      <i className="fas fa-pencil-alt"></i> Neuen Beitrag erstellen
                    </Link>
                  </div> */}
                </div>
              </div>
              <hr />
              <div className="row dashboard-cards-row">

                <div className="col-md-6">
                  <div className="card bg-success shadow dashboard-overview">
                    <div className="card-header">
                      <h4>
                        <i className="far fa-newspaper"></i> News-Beiträge
                        <div className="btn btn-primary btn-sm float-right mr-1 dashboard-addpost-btn">
                          <Link to="/dashboard/beitrag-erstellen">
                            <i className="fas fa-pencil-alt"></i> News-Beitrag erstellen
                          </Link>
                        </div>
                      </h4>
                    </div>
                    <div className="card-body">
                      <table className="table">
                        <thead className="thead-inverse posts-table-head">
                          <tr>
                            <th>Titel</th>
                            <th />
                          </tr>
                        </thead>
                        <tbody>
                          {posts.map(post => (
                            <tr key={post.id}>
                              <td>{post.title}</td>
                              <td className="text-right">
                                {isOpen ? (
                                  <div className="btn-group float-right invisible">
                                    <Link to={`/dashboard/post/${post.id}`} className="btn btn-secondary btn-sm disabled">
                                      <i className="fas fa-arrow-circle-right" /> Details
                                    </Link>
                                    <button
                                      className="btn btn-danger btn-sm"
                                      disabled
                                    >
                                      Löschen
                                    </button>
                                  </div>
                                ) : (
                                  <div className="btn-group float-right">
                                    <Link to={`/dashboard/post/${post.id}`} className="btn btn-secondary btn-sm">
                                      <i className="fas fa-arrow-circle-right" /> Details
                                    </Link>
                                    <button
                                      className="btn btn-danger btn-sm"
                                      onClick={this.toggleDeletePostConfirmation.bind(this, post)}
                                    >
                                      Löschen
                                    </button>
                                  </div>
                                )}
                                  {isOpen && this.state.postId === post.id ? (
                                    <div style={{background: "rgba(0,0,0,0.5)", padding: "2%"}}>
                                      <br/><br/>
                                      <h4><i className="fas fa-exclamation-triangle text-danger"></i> Bist Du sicher?</h4>
                                      <p>Dies kann nicht rückgängig gemacht werden, der Beitrag wird für immer gelöscht!</p>
                                      <button type="button" className="btn btn-secondary mr-1" onClick={this.toggleDeletePostConfirmation}>Abbrechen</button>
                                      <button
                                        type="button"
                                        className="btn btn-danger ml-1"
                                        onClick={this.onDeletePost}
                                      >
                                        Ja, bitte löschen!
                                      </button>
                                    </div>
                                  ) : null}


                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="card bg-secondary shadow dashboard-overview events-card">
                    <div className="overlay">
                      <div className="overlay-text">Wird demnächst aktiviert...</div>
                    </div>
                  {/* <div className="card bg-primary shadow dashboard-overview"> */}
                    <div className="card-header">
                      <h4>
                        <i className="fas fa-folder"></i> Veranstaltungen
                        <div className="btn btn-primary btn-sm float-right mr-1 dashboard-addpost-btn">
                          <Link to="/dashboard/veranstaltung-erstellen">
                            <i className="fas fa-pencil-alt"></i> Veranstaltung erstellen
                          </Link>
                        </div>
                      </h4>
                    </div>
                    <div className="card-body">
                      <table className="table">
                        <thead className="thead-inverse posts-table-head">
                          <tr>
                            <th>Titel</th>
                            <th />
                          </tr>
                        </thead>
                        <tbody>
                          {posts.map(post => (
                            <tr key={post.id}>
                              <td>{post.title}</td>
                              <td className="text-right">
                                <div className="btn-group float-right">
                                  <Link to={`/dashboard/post/${post.id}`} className="btn btn-secondary btn-sm">
                                    <i className="fas fa-arrow-circle-right" /> Details
                                  </Link>
                                  <button
                                    type="button"
                                    className="btn btn-danger btn-sm"
                                    data-toggle="modal"
                                    data-target="#sureDeleteModal"
                                  >
                                    Löschen
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row dashboard-cards-row">
                <div className="col">
                  <div className="card bg-secondary shadow dashboard-overview gallery-card">
                      <div className="overlay">
                        <div className="overlay-text">Wird demnächst aktiviert...</div>
                      </div>
                    <div className="card-header">
                      <h4>
                        <i className="far fa-image"></i> Galerie-Bilder
                        <div className="btn btn-primary btn-sm float-right mr-1 dashboard-addpost-btn">
                          <Link to="/dashboard/galeriebild-hochladen">
                            <i className="fas fa-pencil-alt"></i> Galeriebild hochladen
                          </Link>
                        </div>
                      </h4>
                    </div>
                    <div className="card-body">
                      <table className="table">
                        <thead className="thead-inverse posts-table-head">
                          <tr>
                            <th>Titel</th>
                            <th>Galerie-Bild</th>
                            <th />
                          </tr>
                        </thead>
                        <tbody>
                          {posts.map(post => (
                            <tr key={post.id}>
                              <td>{post.title}</td>
                              {
                                post.image === "" ?
                                  <td><img src="https://via.placeholder.com/140x100" alt="Beitrags-Bild" className="img-fluid table-img" /></td>
                                : <td><img src={post.image} alt="Beitrags-Bild" className="table-img" style={{ width: "30%" }}/></td>
                              }
                              <td className="text-right">
                                <div className="btn-group float-right">
                                  <Link to={`/dashboard/post/${post.id}`} className="btn btn-dark btn-sm">
                                    <i className="fas fa-arrow-circle-right" /> Details
                                  </Link>
                                  <button
                                    type="button"
                                    className="btn btn-danger btn-sm"
                                    onClick={this.toggleDeleteConfirmation}
                                  >
                                    Löschen
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div style={{ minHeight: "100vh" }}>
          <div className="row">
            <div className="col-md-10 dashboard-main-col">
              <h1 className="dashboard-title ml-5">Dashboard</h1>
              <hr />
              <div className="row blog-row">
                <div className="col">
                  <Spinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
}

Dashboard.propTypes = {
  firestore: PropTypes.object.isRequired,
  posts: PropTypes.array,
  visitors: PropTypes.array
}

export default compose(
  firestoreConnect([{
    collection: "posts"
  }]),
  firestoreConnect([{
    collection: "visitors"
  }]),
  connect((state, props) => ({
    posts: state.firestore.ordered.posts,
    visitors: state.firestore.ordered.visitors
  }))
)(Dashboard);