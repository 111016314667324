import React, { Component } from "react";

import Image1 from "../../assets/images/Carousel/Image1.jpg";
import Image2 from "../../assets/images/Carousel/Image2.jpg";
import Image3 from "../../assets/images/Carousel/Image3.jpg";
import Image4 from "../../assets/images/Carousel/Image4.jpg";

import "./Carousel.css";

/* const styles = {
  overlay: {
    position: "absolute",
    top: "0",
    right: "0",
    bottom: "0",
    left: "0",
    background: "rgba(0, 0, 0, 0.7)"
  },
  logo: {
    width: "10%",
    marginBottom: "5%"
  },
  h1: {
    fontWeight: "bold"
  },
  caption: {
    position: "absolute",
    right: "15%",
    bottom: "20px",
    left: "15%",
    zIndex: "10",
    paddingTop: "0px",
    paddingBottom: "20%",
    color: "#fff",
    textAlign: "center"
  }
}; */

export default class carousel extends Component {
  render() {
    return (
      <div
        id="carouselExampleFade"
        className="carousel slide carousel-fade"
        data-ride="carousel"
        data-interval="3000"
      >
        <div className="carousel-inner">
          <div className="carousel-item active">
            {/* <div style={styles.overlay} /> */}
            <img className="d-block w-100" src={Image2} alt="First slide" />
          </div>
          <div className="carousel-item">
            {/* <div style={styles.overlay} /> */}
            <img className="d-block w-100" src={Image3} alt="Second slide" />
          </div>
          <div className="carousel-item">
            {/* <div style={styles.overlay} /> */}
            <img className="d-block w-100" src={Image4} alt="Third slide" />
          </div>
          <div className="carousel-item">
            {/* <div style={styles.overlay} /> */}
            <img className="d-block w-100" src={Image1} alt="Fifth slide" />
          </div>
        </div>
      </div>
    );
  }
}
