import React, { Component } from 'react';
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { firebaseConnect } from "react-redux-firebase";
import { notifyUser } from "../../actions/notifyActions"
import Alert from "../Alert/Alert"
import "./Auth.css"; 

class Login extends Component {
  state = {
    email: "",
    password: ""
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  onSubmit = e => {
    e.preventDefault();

    const { firebase, notifyUser } = this.props;
    const { email, password } = this.state;

    firebase.login({
      email,
      password
    })
    .catch(err => notifyUser("Ungültige Angaben!", "error")); // first param is the message, the second param is the message.type!
  }

  render() {
    const { message, messageType } = this.props.notify;
    
  	return (
      <div className="auth-cont" style={{ minHeight: "100vh" }}>     
        <div className="row ">
          <div className="col-md-6 mx-auto">
            <div className="card">
              <div className="card-body">
                {message ? (
                  <Alert message={message} messageType={messageType} />
                ) : null}
                <h1 className="text-center pb-4 pt-3">
                  <span style={{ color: "var(--orange)"}}>
                    <i className="fas fa-lock" /> Login
                  </span>
                </h1>
                <form onSubmit={this.onSubmit}>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input 
                      type="text"
                      className="form-control"
                      name="email"
                      required
                      value={this.state.email}
                      onChange={this.onChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="password">Passwort</label>
                    <input 
                      type="password"
                      className="form-control"
                      name="password"
                      required
                      value={this.state.password}
                      onChange={this.onChange}
                    />
                  </div>
                  <input type="submit" value="Login" className="btn btn-primary btn-block" />
                </form>
              </div>
            </div>
            <p className="pt-3 text-muted text-center" style={{ fontSize: "0.9rem" }} >Dieser Bereich ist nur für interne Zwecke. Keine Registrierung oder Anmeldung für Außenstehende möglich.</p>
          </div>
        </div>
      </div>
    )
  }
}

Login.propTypes = {
  firebase: PropTypes.object.isRequired,
  notify: PropTypes.object.isRequired,
  notifyUser: PropTypes.func.isRequired
}

export default compose(
  firebaseConnect(),
  connect((state, props) => ({
    notify: state.notify
  }),
  { notifyUser }) // Actions go here!
)(Login)