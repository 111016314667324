import React, { Component } from 'react';
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import "./Home.css";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

import Carousel from "../Carousel/Carousel"
import Image1 from "../../assets/images/Home/_MG_2045.jpg"
import Image2 from "../../assets/images/Home/IMG_0446.JPG"
import Signature from "../../assets/images/Home/Unterschrift-Sybille.png"

const imgRowStyles = {
  background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${Image1})`,
  backgroundSize: "100% 100%",
  backgroundPosition: "center top",
  backgroundRepeat: "no-repeat",
  paddingTop: "5%",
  minHeight: "100vh"
}

class Home extends Component {

  render() {
  	return (
      <div className="home">
        <div className="row home-img-row" style={imgRowStyles}>
          <div className="col text-center home-title">
            <h1 className="font-weight-bold d-none d-sm-block">Sybille Philippart-Weber</h1> {/* Hidden only on XS */}
            <h1 className="font-weight-bold d-block d-sm-none">Sybille<br/>Philippart-Weber</h1> {/* Visible only on XS */}
            <h2 className="font-weight-bold">Goldschmiedemeisterin</h2>
          </div>
        </div>
        <ScrollAnimation
          animateIn="fadeInLeft"
          /* animateOnce="true" */
          delay={300}
        >
          <div className="row">
            <div className="col-md-6">
              <img src={Image2} alt="Sybille Philippart-Weber" className="img-fluid" />
            </div>
            <div className="col-md-6 home-slogan-col">
              <h1 className="slogan pb-3">Design für die Ewigkeit.</h1>
              <p className="">
                In Neuried -Altenheim (bekannt als die Perle der Ortenau) Kirchstrasse 61, in einem idyllischen Fischerhaus aus dem Jahre 1763, befindet sich mein Atelier und gleich daneben mein neuer Ausstellungsraum.
              </p>
              <p className="">
                Hier entstehen in individueller Fertigung Unikate. Ringe, Ohrschmuck, Ketten, Broschen, Arm­bänder aus Silber, Gold und Platin, in Verbindung mit Edelsteinen und Perlen.
              </p>
              <p className="">
                Außerdem fertige Ich Eheringe an, sowie Brautschmuck, edel oder flippig. Für Girls hippen Modeschmuck und allerlei Accessoires.
              </p>
              <p className="">
                Selbstveratändlich nehme ich auch Umarbeitungen, Ringweitenänderungen und Reparaturen an. Auch Ihre Perlenketten können Sie bei mir reinigen und neu knüpfen lassen.
              </p>
              <p className="">
                In der GOLDBOX 61 finden Sie ebenfalls Schmuck aus Kollektionen zu erschwinglichen Preisen.
              </p>
              <p className="">
                Habe ich Sie neugierig gemacht ?
              </p>
              <p className="">
                Schauen Sie rein, ganz einfach unverbindlich, ich berate Sie gerne und freue mich auf Sie!
              </p>
              <p className="">Herzlichst Ihre
              <img src={Signature} alt="Unterschrift" className="img-fluid" /></p>
            </div>
          </div>
        </ScrollAnimation>
        <ScrollAnimation
          animateIn="fadeInRight"
          delay={300}
        >
          <div className="row ribbon-row">
            <div className="col">
              <h1 className="text-center ribbon-text">Design aus Silber, Gold und Platin.</h1>
            </div>
          </div>
        </ScrollAnimation>
        <ScrollAnimation
          animateIn="fadeInLeft"
          delay={300}
        >
          <div className="row carousel-row">
            <div className="col-md-6 carousel-left-text">
              <p className="">
                Für mich ist die Herstellung eines Schmuckstückes für einen Kunden eng verbunden mit dem Kennenlernen des Menschen.
                So wie man für die Herstellung eines Schmuckstückes Fingerspitzengefühl und Kreativität benötigt, so benötigt man Einfühlungsvermögen, um sich in den Kunden und seine Wünschen hineinzuversetzen.
                Material, Form und Farben müssen aufeinander abgestimmt werden und zu Person und Persönlichkeit des Kunden passen. Darauf lege ich großen Wert.<br/>
                Handgefertigter Schmuck soll zeitlos schön sein.
                Kontrastreiche Farben und Materialien geben meinem Schmuck Spannung und Kraft.<br/>
                Ob Steine, Perlen, Gold, Silber, Platin, auch Holz oder eine Feder, alles ist möglich.
                Lassen Sie sich überraschen, besuchen Sie meine Ausstellung in der Goldbox61. Ich zeige Ihnen gerne meine Kreationen und berate Sie gerne beim Kauf, bei Neuanfertigungen, aber auch bei Umarbeitungen.
              </p>
            </div>
            <div className="col-md-6 carousel-self">
              <Carousel />
            </div>
          </div>
        </ScrollAnimation>
      </div>
    )
  }
}

Home.propTypes = {
  firestore: PropTypes.object.isRequired,
  visitors: PropTypes.array
}

export default compose(
  firestoreConnect([{
    collection: "visitors"
  }]),
  connect((state, props) => ({
    visitors: state.firestore.ordered.visitors
  }))
)(Home);