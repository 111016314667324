import React, { Component } from 'react';
import { Link } from "react-router-dom";
/* import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase"; */
import "./Events.css";
//import Spinner from "../Spinner";

class Events extends Component {
  constructor(props) {
    super(props);

    this.state = {
      images: []
    }
  }

  render() {
      return (
        <div className="row events-row">
          <div className="col">
            <h1 style={{ color: "#e79239" }}><i className="fas fa-images"></i> <span className="satisfy">Veranstaltungen</span></h1>
            <hr/>
            <div className="card-colums">

            <Link to="/veranstaltungen/modenschauoffenburg" style={{textDecoration: "none"}}>
              <div className="card p-3 m-3 text-center events-folder-card">
                <h1><i className="fas fa-folder"></i></h1>
                <h1>Modenschau Offenburg</h1>
              </div>
            </Link>

            <Link to="/veranstaltungen/glaswohnen" style={{textDecoration: "none"}}>
              <div className="card p-3 m-3 text-center events-folder-card">
                <h1><i className="fas fa-folder"></i></h1>
                <h1>Event bei Glaswohnen 2016</h1>
              </div>
            </Link>

            <Link to="/veranstaltungen/ichenheim" style={{textDecoration: "none"}}>
              <div className="card p-3 m-3 text-center events-folder-card">
                <h1><i className="fas fa-folder"></i></h1>
                <h1>Ichenheim 950 Feier</h1>
              </div>
            </Link>

            <Link to="/veranstaltungen/terra" style={{textDecoration: "none"}}>
              <div className="card p-3 m-3 text-center events-folder-card">
                <h1><i className="fas fa-folder"></i></h1>
                <h1>TERRA Businesscenter Offenburg</h1>
              </div>
            </Link>

            <Link to="/veranstaltungen/novembermarkt" style={{textDecoration: "none"}}>
              <div className="card p-3 m-3 text-center events-folder-card">
                <h1><i className="fas fa-folder"></i></h1>
                <h1>Novembermarkt 2018</h1>
              </div>
            </Link>

            </div>
          </div>
        </div>
      )
  }
}

export default Events;